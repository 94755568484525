const themes = [
    {
        "id": "5de73a22-ac2a-44f6-9ae1-e59cc2c04a81",
        "theme_description": "Casamento"
    },
    {
        "id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "theme_description": "Infantil"
    },
    {
        "id": "196854a2-735b-4a17-8eb4-7583ef57f130",
        "theme_description": "Bolo de Flores"
    },
    {
        "id": "af9e5867-6dad-4f30-8e6e-ed97145cea25",
        "theme_description": "90 Anos"
    },
    {
        "id": "1b131c45-363f-497d-a69a-e44df260e40e",
        "theme_description": "Dia das Mães"
    },
    {
        "id": "db592ea3-4e7a-4598-b792-09767ccec5be",
        "theme_description": "Dia dos Pais"
    },
    {
        "id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
        "theme_description": "Tortas"
    },
    {
        "id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
        "theme_description": "15 anos"
    },
    {
        "id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
        "theme_description": "Formatura"
    }
]

export default themes