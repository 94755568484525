<template>
  <div
    id="accordionFlushProducts"
    class="accordion accordion-flush"
  >
    <div class="title-accordion">
      <h3>Temas</h3>
    </div>

    <div
      v-if="themesLoad"
      class="spinner-area"
    >
      <b-spinner />
    </div>

    <div v-if="!themesLoad">
      <div
        v-for="item in getThemes"
        :key="item.id"
      >
        <h2
          :id="'flush-headingOne-'+item.id"
          class="accordion-header"
        >
          <button
            :id="item.id"
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#flush-collapseOne-'+item.id"
            aria-expanded="false"
            :aria-controls="'flush-collapseOne-'+item.id"
            @click="handleGetCategories(item)"
          >
            {{ item.theme_description }}
          </button>
        </h2>
        <div
          :id="'flush-collapseOne-'+item.id"
          class="accordion-collapse collapse"
          :aria-labelledby="'flush-headingOne-'+item.id"
          data-bs-parent="#accordionFlushProducts"
        >
          <div class="accordion-body">
            <div
              v-if="categoriesLoad"
              class="spinner-area"
            >
              <b-spinner />
            </div>

            <ul
              v-if="!categoriesLoad"
              class="list-style"
            >
              <li
                v-for="category in getCategories"
                :key="category.id"
              >
                <button
                  class="btn"
                  @click="handleGetProducts(category)"
                >
                  <feather-icon icon="CircleIcon" />
                  {{ category.category_description }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'SidebarMenuFilter',

  components: {
    BSpinner,
  },

  props: {
    themes: {
      type: Array,
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      themesLoad: false,
      categoriesLoad: false,
    }
  },

  computed: {
    getThemes() {
      return this.themes
    },

    getCategories() {
      return this.categories
    },
  },

  watch: {
    themesLoad(item) {
      return item
    },

    categoriesLoad(item) {
      return item
    },
  },

  methods: {
    async handleGetCategories(theme) {
      const element = document.getElementById(theme.id)

      if (element.getAttribute('aria-expanded') === 'true') {

        this.$emit('handleGetCategories', theme)
      }
    },

    async handleGetProducts(category) {
      await this.$emit('populateCategoriesArray', category)
      await this.$emit('handleGetProducts')
    },
  },
}
</script>

<style scoped>
@import "../../../../assets/scss/form-group.scss";
@import './style.scss';
</style>
