const categories = [
    {
        "id": "5145e7b1-b4ee-4cac-a927-7be6c6a5e383",
        "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
        "category_description": "Bolo 15 anos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "795ccd94-0e94-4a25-8ff6-97af01ff482b",
        "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
        "category_description": "Doces 15 anos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "d79767dc-79d6-4ba8-9d9a-0583d224dc0a",
        "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
        "category_description": "CupCakes 15 anos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "3fc2596f-02cc-40bb-91e7-6af77ccce265",
        "theme_id": "5de73a22-ac2a-44f6-9ae1-e59cc2c04a81",
        "category_description": "Bolos Casamento",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "4a074095-e0fc-4bc7-9b23-724df6ad942e",
        "theme_id": "5de73a22-ac2a-44f6-9ae1-e59cc2c04a81",
        "category_description": "CupCakes Casamento",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "9d0c2727-b243-41c0-a939-38f6c72a6a25",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Bolos Infantis",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "c6687ae9-742c-4bfd-8977-a781887b8f46",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Doces Infantis",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "1890cb8a-f55a-488c-8a0d-73cc95a7de02",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "CupCakes infantis",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Lego",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Jardim Encantado",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Heróis",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "dbea5b19-edd9-4419-8b58-465e8244b7c8",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Among Us",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "3f217586-25f2-421c-b79b-0854946c6a71",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Personalizados",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "1ddc6736-e005-4aaa-96b6-fe95223d2956",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Barbie",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Patrulha Canina",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Harry Potter",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "5345f605-cd86-41d1-a50e-2199466dd1b9",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Frozen",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Princesas",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "13a17d4d-c587-4cc4-98b7-cac5ba161444",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Lilo e Stitch",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "52ecf1da-9399-4074-b390-89119c1b7987",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Religiosos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Minions",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "a899d527-3063-4758-9746-cfe1534b98af",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "My Little Pony",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "ea1db169-e6bf-48d5-8009-62e84bbc3e50",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Now United",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Batizado",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Masha e o Urso",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Unicórnios",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Meetoo Dolls",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "ec143be3-9455-4568-9c0a-6bd573997228",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Monstros S.A",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Woodland",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "1648b5a5-8d1f-42c1-b0df-78393d0236b4",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Ben 10",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "82485f1b-4607-4f37-8329-7e637a2a94eb",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Peter Rabbit",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "847f4357-81e1-4270-858f-e19f26b3a08e",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Animais",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "91756096-5f2e-42ce-90be-69223d4163a8",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Futebol",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "2c9495d8-8e7d-4989-a305-6424e4ee9154",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Bolos de Festa Junina",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "4f945388-a245-4313-8255-1a355020e52c",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Doces de Festa Junina",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "15e76d51-f3c5-4549-90d7-7cf348ffb694",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Toy Story",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "cec75408-e311-476b-8d93-16d33899620d",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Star Wars",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "c7cad0da-b635-4b61-8ef6-a1106d88c4aa",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Ursinho Pooh",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Baby Shark",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "6e0cbaf7-3a06-4a6f-9d0d-5fa79e61c3a9",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "LOL",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8c03740c-b774-43f0-b3f2-c27c6ef71e92",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Asterix e Obelix",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8a2a2972-fbfb-4a4f-b880-61e9c3afb543",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Cry Baby",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8b304ecf-41dd-4e2d-acf9-24fae464534f",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Sylvanian Families",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "51b82fda-bed5-4cf6-adac-149cbb1917a4",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Bob Esponja",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Pequena Sereia",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Bita",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Branca de Neve",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8e1ce01e-67d4-493d-8ddc-69764e4aa30b",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Marie",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "e9d9dfa3-3da3-49ab-a7d4-e569974b1bc3",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Bob Zoom",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Mickey e Minnie",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "9f40f05a-0b27-4242-ac62-f72768257148",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Timão e Pumba",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Galinha Pintadinha",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "a8e47f88-e15e-4d87-9429-8f0220cbb60d",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Show da Luna",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "4ed79a24-a396-4200-b462-9c127ff57ab0",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Alladin",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "a90178df-392b-41d4-bcfa-49e4fb34d460",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Halloween",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "9a9653c7-0d64-42ee-b5c9-5bd07dfb7973",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Daniel Tigre",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "273df079-0bb3-45ce-a192-67360177ed29",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Jovens Titãs",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "0c744a01-f5ca-409c-b8a7-9eda59ce69c3",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Hot Wheels",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "81a232fd-0104-49a7-a669-dea3d5852750",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Carros",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f43f11cc-24af-46ea-b250-2c81cba4ddd4",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Disney Mini",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "c9a9cc22-2853-40cb-8bbc-2316a2edf04c",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Pj Masks",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "e9bc4d0f-e4fe-4632-a524-5134275131d0",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Kokeshi",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "c29b1c96-2970-4ead-9449-1e3d69bba995",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Dragões Equipe de Resgate",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "09d4dd9a-b418-459c-9784-6028c194136b",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Dinossauros",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "a4e5cea0-05a3-4465-9498-4a911df83f50",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Arca de Noé",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Ursinhos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "3e8f1535-55aa-4f1b-8ab3-69930d4b8afe",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Fundo do Mar",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Safari",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "2e23b19a-1ef2-45a9-87f3-902d285e17dd",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Dálmatas",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "bca3a878-d222-4c62-8edc-acb83838b282",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Passarinhos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "9e906056-5de1-4700-bb41-6125db703ee8",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Coelhos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "ffa291c1-b117-4d5d-82e4-a061c6181e12",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Páscoa",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "61634f0c-0733-44c2-8c61-a12486537569",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Fazendinha",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8828f326-b50a-42aa-882e-32fe35cbaeed",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Elefantinhos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "766e3f23-5ab7-4783-90b6-ca11e4659a9a",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Pets",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "617dc314-4f71-429e-80d1-c0422d5e7503",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Esquilos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "2eb50d53-32c0-4778-bf69-70483f92bc3a",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Tubarões",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "fe4643aa-2208-4f22-8121-fdd7b44e9e3d",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Abelinhas",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Bichinhos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "1abe4f26-017c-4214-aa88-c4b4d18bff8d",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "PK XD",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "2b996ce1-a060-4fdd-882f-eb5030a8d450",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Alice no País das Maravilhas",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "899b3dc1-8739-4cab-b576-7a173b143e7c",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Lhamas",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "de04767c-acfe-4556-b38b-b42a023fd121",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Rei Leão",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "43f33064-d669-4041-bcdb-4c322b6a1fa6",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Pequeno Principe",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "5cdadf00-03d6-4f7d-b359-ee0a46955223",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Fortinite",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "1f115945-4a7a-468c-8b68-19f01dd76d00",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Peppa",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "339271a7-ac92-450a-976a-e89156792d38",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Guardiões da Galáxia",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "8371a25b-988c-4bfd-ac47-a7d217e13bf2",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "DPA",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "3c80d271-b6af-46b2-8d31-4bf344120269",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Rapunzel",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "b8e61d47-00e8-4a45-9f8d-9d33fc84eb72",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Pintinhos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "b2a2732a-7c3b-474b-8706-76415559517b",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Rayman",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "4a87c165-ca3c-4de2-a40b-c18c74827fbd",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Scooby Doo",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "474341a8-3056-4ddc-94c5-5152921620b5",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Guarda do Leão",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "518f7a6e-43d2-45c8-ad8b-489c5ca63074",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Fidget Toys",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "b959f243-b8c7-40e5-8499-3e056a902eb6",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Naruto",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f3e26eb6-115e-4e79-8959-1c129d7abfc1",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Piñata",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f2565eb2-a667-4750-bda1-7d32a41baa9e",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Chapeuzinho Vermelho",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "32b72d9e-2940-4b74-a955-a254d72f77d3",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Construção",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "864991ca-469e-4d02-9752-f9757b3c1490",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "A Bela e a Fera",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "eedaf6db-ab07-4aa5-a4b2-00a191ea05ac",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Balões",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "aca2aa58-44ca-4242-bc1c-d157ce080f06",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Tom e Jerry",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "71729ebe-6c5c-434f-8b7c-fff5454f13b1",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Flores",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "452aa180-8a5c-4f59-a0b4-04a835294337",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Ladybug",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "a474da37-8d16-4039-ba7e-524f071d1ab0",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Pokemon",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "26c5743c-9340-499b-bf0b-75aaf80117c7",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Ursinhos Carinhosos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "0429c632-a1f6-4f90-bfb6-79ed3e888553",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "One Piece",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "0a29f528-02a2-48bf-9ed7-9c8642f87d5c",
        "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
        "category_description": "Branca de Neve",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "e5c1ccea-0417-4ad5-bdba-f68c437a0a33",
        "theme_id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
        "category_description": "Bolos Formatura",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "66c156af-4395-4ef7-9eb7-b2319511f8a8",
        "theme_id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
        "category_description": "Doces Formatura",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "06bcc289-086f-4425-b764-c3d5b87e0d5e",
        "theme_id": "cbd0dddb-329e-4113-8282-324ba9e2e79e",
        "category_description": "Todos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "1d724543-7ad8-47b2-9436-b80a5ee1e0d9",
        "theme_id": "1b131c45-363f-497d-a69a-e44df260e40e",
        "category_description": "Bolos Dia das Mães",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "d9f56627-a44d-4999-9030-4df2ba279169",
        "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
        "category_description": "Bolos Dia dos Pais",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "72e63ebf-d97a-4ec4-96aa-34d0c6a2cb5a",
        "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
        "category_description": "Palha Italiana",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "b8c0a6ed-4bcc-4d15-b22c-95491523ff85",
        "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
        "category_description": "Torta de Nozes",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "f0d6ab9e-f948-43ce-b604-657ab3fa248e",
        "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
        "category_description": "Moranguita",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "3dd4ca8e-6179-4ec9-ab7b-88c5e2451fd8",
        "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
        "category_description": "Sabores de Torta",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "0a717b29-991b-4947-8eab-b003cca102bc",
        "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
        "category_description": "Bolos de Flores",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    },
    {
        "id": "3e118823-1540-4786-8815-3f0ac91171db",
        "theme_id": "af9e5867-6dad-4f30-8e6e-ed97145cea25",
        "category_description": "90 Anos",
        "active": true,
        "created_at": "2023-01-26T11:23:33.171795Z",
        "updated_at": "2023-01-26T11:23:33.171795Z"
    }
]

export const getCategoriesByThemeId = themeId => {
    return categories.filter(category => category.theme_id === themeId);
}

export default categories