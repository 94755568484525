<template>
  <section class="main-products">
    <b-container>
      <b-row class="mb-4">
        <b-col
          sm="6"
          lg="4"
          class="mb-3"
        >
          <b-form-input
            id="reset-password-new"
            v-model="search.productDescription"
            class="form-control-merge custom-filter-input"
            placeholder="Nome do Produto"
            autocomplete="off"
          />
        </b-col>

        <b-col
          sm="6"
          lg="4"
          class="d-lg-none .d-xl-block mb-3"
        >
          <div class="bg-column">
            <v-select
              v-model="chooseTheme"
              :options="themes"
              variant="custom"
              item-text="theme_description"
              item-value="id"
              placeholder="Temas"
              label="theme_description"
              @input="handleGetCategories(chooseTheme)"
            />
          </div>
        </b-col>

        <b-col
          sm="6"
          lg="4"
          class="d-lg-none .d-xl-block mb-3"
        >
          <div class="bg-column">
            <v-select
              id="categories"
              v-model="search.category"
              :disabled="!chooseTheme"
              :options="categories"
              variant="custom"
              item-text="category_description"
              item-value="id"
              placeholder="Categorias"
              label="category_description"
              @input="filterName = 'category'; handleGetProducts(filterName)"
            />
          </div>
        </b-col>

        <b-col
          sm="12"
          lg="5"
          class="mb-3"
        >
          <button-form
            button-class="me-3 mb-2"
            :height="40"
            :line-height="37"
            @action="filterName = 'productDescription'; handleGetProducts(filterName)"
          >
            <feather-icon
              class="me-1"
              icon="SearchIcon"
            />
            Buscar
          </button-form>

          <button-form
            component-mode="outline"
            :height="40"
            :line-height="37"
            @action="clearFilters"
          >
            <feather-icon
              class="me-1"
              icon="FilterIcon"
            />
            Limpar
          </button-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="3"
          class="d-none d-lg-block"
        >
          <sidebar-menu-filter
            ref="sidebarMenuFilter"
            :themes="themes"
            :categories="categories"
            @populateCategoriesArray="populateCategoriesArray"
            @handleGetCategories="handleGetCategories"
            @handleGetProducts="handleGetProducts(filterName)"
          />
        </b-col>

        <b-col
          md="12"
          lg="9"
        >
          <b-row
            v-if="productsLoad"
          >
            <div
              v-if="productsLoad"
              class="spinner-area"
            >
              <b-spinner />
            </div>
          </b-row>

          <b-row
            v-if="!productsLoad"
          >
            <b-col
              v-for="(item, index) in products"
              :key="index"
              sm="12"
              md="6"
              lg="4"
              class="mb-4"
            >
              <product-card
                :product-image="setProductImageUrl(item.image[0].path)"
                :product-description="item.product_description"
                :product-unique-name="item.product_unique_name"
                text-button="Detalhes"
              />
            </b-col>
          </b-row>

          <b-row
            v-if="!productsLoad"
          >
            <b-col
              cols="12"
            >
              <CustomPagination
                  :current_page="currentPage"
                  :row_count_page="rowCountPage"
                  @page-update="pageUpdate"
                  :total_data="totalData"
                  :page_range="pageRange"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
} from 'bootstrap-vue'
import ProductCard from '@/components/ProductCard/ProductCard.vue'
import { getAllThemes } from '@/utils/requests/themes'
import SidebarMenuFilter from '@/views/Products/components/SidebarMenuFilter/SidebarMenuFilter.vue'
import { getAllCategories } from '@/utils/requests/categories'
import { getAllProducts } from '@/utils/requests/products'
import { getArrayAttr, setProductImageUrl } from '@/utils/helpers/helpers'
import ButtonForm from '@/components/Buttons/ButtonForm.vue'
import vSelect from 'vue-select'
import themes from "@/utils/data/themes";
import CustomPagination from "@/components/Pagination/CustomPagination";
import products, { filterProductsList } from "@/utils/data/products";
import {getCategoriesByThemeId} from "@/utils/data/categories";

export default {
  name: 'Products',

  components: {
    CustomPagination,
    BContainer,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    SidebarMenuFilter,
    ProductCard,
    ButtonForm,
    vSelect,
  },

  data() {
    return {
      setProductImageUrl,

      productsLoad: false,

      orderOptions: [
        { value: 'asc', text: 'A-Z' },
        { value: 'desc', text: 'Z-A' },
      ],

      columnOrder: null,

      themes: [],
      categories: [],
      products: [],

      chooseTheme: null,

      filterName: '',
      item: '',

      search: {
        productDescription: '',
        category: '',
      },

      // Pagination
      currentPage: 0,
      rowCountPage: 10,
      totalData: 0,
      pageRange: 4,
    }
  },

  mounted() {
    this.handleGetThemes()
    this.handleGetProducts(this.filterName)
  },

  methods: {
    handleGetThemes() {
      this.themes = themes
    },

    handleGetCategories(chooseTheme) {
      this.$refs.sidebarMenuFilter.categoriesLoad = true

      if (!chooseTheme) {
        this.search.category = null

        return false
      }

      this.categories = getCategoriesByThemeId(chooseTheme.id)

      this.$refs.sidebarMenuFilter.categoriesLoad = false

      return true
    },

    async handleGetProducts(filterName) {
      if(filterName === 'productDescription') {
        this.item = this.search.productDescription
      }

      if(filterName === 'category') {
        this.item = this.search.category.id
      }

      this.productsLoad = true

      this.products = []

      const products = await filterProductsList(this.filterName, this.item)

      this.handleGetData(products)

      this.productsLoad = false
    },

    populateCategoriesArray(category) {
      this.filterName = 'category'
      this.search.category = category
    },

    clearFilters() {
      this.filterName = ''
      this.item = ''

      this.search = {
        productDescription: '',
        category: '',
      }

      this.columnOrder = null
      this.chooseTheme = null

      this.handleGetProducts(this.filterName)
    },

    setParams() {
      // return {
      //   perPage: this.paginationData.defaultSize,
      //   page: this.paginationData.currentPage,
      //   columnOrder: this.columnOrder ? this.columnOrder.value : null,
      //   productDescription: this.search.productDescription,
      //   categories: this.search.categories
      //     ? getArrayAttr(this.search.categories, 'id')
      //     : [],
      // }
    },

    handleGetData(response) {
      this.totalData = response.length;
      this.products = response.slice(
          this.currentPage * this.rowCountPage, (this.currentPage * this.rowCountPage) + this.rowCountPage
      );
      if (this.products.length === 0 && this.currentPage > 0) {
        this.pageUpdate(this.currentPage - 1);
      }
    },

    pageUpdate(pageNumber) {
      this.currentPage = pageNumber;
      this.handleGetProducts(this.filterName);
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
