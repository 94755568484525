<template>
  <div class="product_pagination" v-if="totalPages > 0">
    <div class="middle_list">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
          <li class="page-item" v-if="firstPage">
            <a class="page-link" @click="updatePage(0)" href="javascript:void(0)">1</a>
          </li>

          <li class="page-item page-link text-dark px-3 fa fa-ellipsis-h"  v-if="firstPage"></li>

          <li class="page-item" :class="{'active': current_page === page}" v-for="(page,index) in pages" :key="index">
            <a class="page-link" @click="updatePage(page)" href="javascript:void(0)">{{ page + 1 }}</a>
          </li>

          <li class="page-item page-link text-dark px-3 fa fa-ellipsis-h"  v-if="lastPage"></li>

          <li class="page-item" v-if="lastPage">
            <a class="page-link" @click="updatePage(totalPages - 1)" href="javascript:void(0)">{{ totalPages }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomPagination",

  props:['current_page', 'row_count_page','total_data','page_range'],

  methods: {
    updatePage(pageNumber) {
      this.$emit('page-update', pageNumber);
    },
  },

  computed:{
    prev(){
      return this.current_page - 1;
    },
    next(){
      return this.current_page + 1;
    },
    rangeStart(){
      let start = this.current_page - this.page_range;
      return  (start > 0) ? start : 0;
    },
    rangeEnd(){
      let end = this.current_page + this.page_range;
      return (end < this.totalPages) ? end : this.totalPages;
    },
    pages(){
      let pages = [];
      for(let i = this.rangeStart; i < this.rangeEnd; i++){
        pages.push(i);
      }
      return pages;
    },
    totalPages(){
      return Math.ceil(this.total_data / this.row_count_page);
    },
    firstPage(){
      return this.rangeStart !== 0;
    },
    lastPage(){
      return this.rangeEnd < this.totalPages
    },
    showPrevLink() {
      return this.current_page !== 0;
    },
    showNextLink() {
      return this.current_page !== (this.totalPages - 1);
    }
  },
}
</script>

<style scoped>
.page-link {
  color: #f19596;
  border: 1px solid #f19596 !important;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #f19596;
  border-color: #f19596;
  margin-left: 0 !important;
}

.page-item:not(:first-child) .page-link {
  margin-left: 0 !important;
}
</style>